
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as geometry from 'spherical-geometry-js';
import Tag from '../Tag'
import './styles/index.sass'

import budgetIcon from '../../../static/img/budgetIcon.png';
import coinsIcon from '../../../static/img/coinsIcon.png';
import wingsIcon from '../../../static/img/wingsIcon.png';
import hamburgerIcon from '../../../static/img/fastFood.png';
import pizzaIcon from '../../../static/img/pizzaIcon.png';
import mexicanFoodIcon from '../../../static/img/tacoIcon.png';
import sushiIcon from '../../../static/img/sushiIcon.png';
import tikkaIcon from '../../../static/img/tikka.png';
import carrotIcon from '../../../static/img/carrotIcon.png';
import closeIcon from '../../../static/img/closeIcon.png';
import unknownIcon from '../../../static/img/unknownIcon.png';

const BlogPostTemplate = class extends React.Component {

  state = {
    posts: '',
    position: []
  }

  calculateDistance = (position) => {

    if(position){
      const BazookaPosition = new geometry.LatLng(59.318286,18.075884);

      /* Extract latitude and longitude from the position parameter ( = an array) */
      let lat = position[0];
      let lng = position[1];
      let foodPosition = new geometry.LatLng(lat,lng); 

      let distance = geometry.computeDistanceBetween(BazookaPosition,foodPosition);
      distance = Math.round(distance);

      return distance;
    }
    return;
  }

  getPriceClassIcon = (category) => {

	switch(category) {
	  case 'Budget': return budgetIcon
	  case 'Mellan': return coinsIcon
	  case 'Dyrt': return wingsIcon
	  default:
		  return ''
	} 
  }

  getFoodIcon = (category) => {
	category = category.toLowerCase(); 

	switch(category) {
		case 'asiatiskt': return sushiIcon
		case 'sushi': return sushiIcon
		case 'pizza': return pizzaIcon
		case 'hamburgare': 
		case 'snabbmat': 
			return hamburgerIcon
		case 'husmanskost': return unknownIcon
		case 'mexikanskt': 
		case 'tacos':
			return mexicanFoodIcon
		case 'indiskt': return tikkaIcon
		case 'vegetariskt': return carrotIcon
		case 'blandat': return unknownIcon
		default:
			return unknownIcon
	} 
}

  // componentDidUpdate(){

  //     function queryConvert(){
  //       var queryStr = window.location.search,
  //         queryArr = queryStr.replace('?','').split('&'),
  //         queryParams = [];

  //       for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
  //           var qArr = queryArr[q].split('=');
  //           queryParams[qArr[0]] = qArr[1];
  //       }

  //       this.setState({ position: queryParams })

  //       // return queryParams;
  //     }
  //   }

  render(){

    console.log(this.props);

    return (

      this.props ?

      <React.Fragment>

       <div className="post__close">
			<Link to="/">
				<img src={closeIcon} alt="Stäng-ikon" />
			</Link>
			
		</div>

        <div className="post__container">
            

            <h1 className="post__title">{this.props.title}</h1>
            {/* <Link className="preview__title" to={'maps/?lat=' + this.props.post.frontmatter.position[0] + '&lng=' + this.props.post.frontmatter.position[1]} >
              <p className="post__address">
                {this.props.address} (klicka här för karta)
              </p>
            </Link> */}

            <Link className="post__address" to={'maps/?lat=' + this.props.position[0] + '&lng=' + this.props.position[1]} >
                <strong>Adress: </strong>{this.props.address} (klicka här för karta)
            </Link>

			<div className="post__serving">
				<strong>Serverar: </strong> 
				{/* {this.props.post.frontmatter.category} */}
				{this.props.categories.map((category, i) => ( 
					(this.props.categories.length === i + 1) ? category : (category + ', ')
				))}
			</div>
			

            {/* Comment: Best practise about using clear?? */}
			{/* <div class="clear"></div> */}
			
			<div className="post__bubbles-container">
			
				<div className="post__bubble post__bubble-position">
					{this.calculateDistance(this.props.position)} meter
				</div>

				<div className="post__bubble post__bubble-category">
					<img src={this.getFoodIcon(this.props.categories[0])} alt="Kategori-ikon" />
				</div>

				<div className="post__bubble post__bubble-priceClass">
					<img src={this.getPriceClassIcon(this.props.priceClass) } alt="Prisklass-ikon" />
				</div>
			</div>

            <p className="post__description">
                {this.props.description}
            </p>

            <img className="post__image" src={this.props.image.publicURL} alt="Restaurant" />


			<h4>Tags:</h4>
			

			<ul className="post__taglist">
				{this.props.tags.map((tag, i) => (
					<Tag key={i} tag={tag} className="tag__regular" />  
				))}
            </ul>
        
        </div>


      </React.Fragment>
      : 'hejhej'
    )
}

}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  website: PropTypes.string,
  address: PropTypes.string,
  priceClass: PropTypes.string, 
  title: PropTypes.string,
  cateogry: PropTypes.string,
  date: PropTypes.string,
  helmet: PropTypes.object,
  position: PropTypes.string,
  image: PropTypes.object,
  tags: PropTypes.object
}

BlogPostTemplate.defaultProps = {
  content: {},
  contentComponent: () => {},
  description: '',
  website: '',
  address: '',
  priceClass: '', 
  title: '',
  cateogry: '',
  date: '',
  helmet: {},
  position: '',
  image: {},
  tags: {},
  body: ''
}

export default BlogPostTemplate;