import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogPostTemplate from '../components/BlogPostTemplate'
import { HTMLContent } from '../components/Content'

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>

      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        tags={post.frontmatter.tags}
        website={post.frontmatter.website}
        categories={post.frontmatter.categories}
        priceClass={post.frontmatter.priceClass}
        position={post.frontmatter.position}
        address={post.frontmatter.address}
        description={post.frontmatter.description}
        image={post.frontmatter.image}

        helmet={
          <Helmet
            titleTemplate="%s | Blog"
          >
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.distance}</p>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
      />

    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost;

// Comment: add 'image' here:
export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        address
        title
        categories
        priceClass
        position
        image {
          name
          absolutePath
          publicURL
        }
      }
    }
  }
`
