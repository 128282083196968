import React from 'react'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import './styles/index.sass'


const Tag = ({tag, className}) => {

    return (
        <li key={tag + `tag`} className={className}>
            <Link to={`/tags/${kebabCase(tag)}/`}>
                {tag}
                
            </Link>
        </li>
       
    );
  }

  export default Tag;


